import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Note to job agencies' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        If you are looking to chat to me about a job or want my CV / resume then the best thing to do
          is to reach out to me on Linkedin and we can take it from there.
      </Text>
    </Card>
  </Section>
)

export default Commitment
